<template>
  <div @click="handleClick">
    <slot></slot>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import api from '@/api/account'
export default {
  name: 'Publish',
  props: {
    releaseInfoPath: {
      type: String,
      default: '/content/request/release',
    },
    checkLawyer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapGetters(['userInfo', 'orgIntro']),
    allowPublish() {
      const isOrganization = ['LAW_OFFICE', 'TRANSLATE', 'OTHER'].includes(
        this.userInfo.userType
      )
      return isOrganization
        ? this.orgIntro && this.orgIntro.description && this.orgIntro.field
        : true
    },
  },
  mounted() {
    this.getIntro()
  },
  activated() {
    this.getIntro()
  },
  methods: {
    ...mapActions(['getOrgIntro']),
    getIntro() {
      this.loading = true
      if (['LAW_OFFICE', 'TRANSLATE'].includes(this.userInfo.userType)) {
        this.getOrgIntro().then((res) => {
          this.loading = false
        })
      }
      if (this.userInfo.userType === 'LAWYER' && this.checkLawyer) {
        api.getAccount('LAWYER').then(res => {
          this.account = res
        })
      }
    },
    handleClick() {
      if (this.userInfo.userType === 'LAWYER' && this.checkLawyer) {
        this.handleLawyer()
      } else {
        this.handleOrganization()
      }
      // this.$emit('click')
    },
    handleOrganization() {
      if (this.allowPublish) {
        this.$router.push(this.releaseInfoPath)
      } else {
        // debugger
        this.$message.warning('请先完善机构简介信息')
        setTimeout(() => {
          this.$router.push('/usercenter/organization')
        }, 3000)
      }
    },
    handleLawyer() {
      if (this.account && this.account.organizationId) {
        this.$router.push(this.releaseInfoPath)
      } else {
        this.$message.warning('请先绑定律所')
        setTimeout(() => {
          this.$router.push('/usercenter/profile')
        }, 3000)
      }
    }
  },
}
</script>

<style>
</style>
